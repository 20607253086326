import axios from "axios"
import Cookies from "js-cookie";
import jsCookie from "js-cookie"
import md5Hex from 'md5-hex';

const sendLeadUrl = "/lead"

const getParam = (p) => {
	var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
	return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const getRayonCodes = (rayonName) => {
	let result = []
	if (rayonName === undefined) {
		return []
	}
	let rayons = rayonName.split(", ")
	rayons.map((rayon) => {
		Object.keys(domainSettings.locations).map((key) => {
			if (domainSettings.locations[key].NameFull === rayon) {
				result.push(domainSettings.locations[key].Path)
			}
		})
	})
	return result
}

const getSdachaList = (sdacha) => {
	if (sdacha === undefined) {
		return []
	}
	let list = sdacha.split(", ")
	let result = []
	list.forEach((sdacha) => {
		sdacha.split(' ').forEach((path) => {
			if (parseInt(path) > 0) {
				result.push(path)
			}
		})
	})
	return result
}

const getRooms = (rooms) => {
	let result = []
	let list = rooms.split(", ")
	list.forEach((room) => {
		if (room === "Студии") {
			result.push(0)
		} else if (room === "1 - комнатные квартиры") {
			result.push(1)
		} else if (room === "2 - комнатные квартиры") {
			result.push(2)
		} else if (room === "3 - комнатные квартиры") {
			result.push(3)
		} else if (room === "4 - комнатные квартиры") {
			result.push(4)
		}
	})
	return result
}

const downloadFile = (url) => {
	let filename = domainSettings.domain.url+ ".pdf";
	var link = document.createElement('a');
	link.href = url;
	link.download = filename;
	link.setAttribute('target', '_blank')
	document.body.appendChild(link); 
	link.click(); 
	document.body.removeChild(link);
}

const SendData = (phone, sdacha, rooms, celtype, sendToCc = false) => {
	let dataLayerInfo = sendLeadToDataLayer(phone, sdacha, rooms, false)
	console.log(dataLayerInfo)
	let leadgen = {
		"AMO_ID": "false",
		"loc_type": "",
		"roistatVisitId": jsCookie.get('roistat_visit'),
		"act": "tmp",
		"vtor": "0",
		"jk_id": "",
		"TEMA": "Заявка с одноэкранника",
		"m_codes": "",
		"f_info[]": [],
		"yclid": getParam('yclid') === null ? "" : getParam('yclid'),
		"gclid": getParam('gclid') === null ? "" : getParam('gclid'),
		"send_type": "",
		"l_t": "la",
		"set_bd": "1",
		"s[sid]": "",
		"s[d]": domainSettings.domain.url,
		"s[r]": "",
		"s[uri]": decodeURIComponent(window.location.search),
		"fb_id": domainSettings.domain.facebook,
		"metrika_id": domainSettings.domain.yandex,
		"ut_type": domainSettings.domain.url,
		"s[ua]": window.navigator.userAgent,
		"a_sda4a[]": [...sdacha],
		"rooms_new[]": [...rooms],
		"clientID": document.getElementById('clientID')?document.getElementById('clientID').value:"",
		"_fbp": jsCookie.get("_fbp"),
		"_fbc": jsCookie.get("_fbc"),
		"ga_cid": googleClientID(jsCookie.get("_ga")),
		"S_ID": domainSettings.city.portal_id,
		"s_name": domainSettings.city.Name,
		"s[ip]": domainSettings.ip,
		"user_ip": domainSettings.ip,
		"utm_content": "1ekran",
		"name": "",
		"phone": "",
		"celType": "",
		"s[cel]": "",
	}

	if (getParam('utm_campaign') !== null) {
		leadgen["utm_campaign"] = getParam('utm_campaign')
	}
	if (getParam('utm_medium') !== null) {
		leadgen["utm_medium"] = getParam('utm_medium')
	}
	if (getParam('utm_term') !== null) {
		leadgen["utm_term"] = getParam('utm_term')
	}
	if (getParam('utm_source') !== null) {
		leadgen["utm_source"] = getParam('utm_source')
	}
	if (getParam('send') !== null) {
		leadgen["utm_send"] = getParam('send')
	}

	if (getParam('utm_send') !== null) {
		leadgen["utm_send"] = getParam('utm_send')
	}

	if (domainSettings.domain.send_to_call_center || sendToCc) {
		leadgen["utm_send"] = "cc"
	}

	if (sendToCc) {
		leadgen["no_orders"] = "y"
	}

	//console.log(raionsName)
	let text = ["Сдача - "+sdacha+"\n"+ "Кол-во комнат - "+ rooms];
	
	leadgen = { ...leadgen, "f_info[]": text }
	leadgen.phone = phone

	leadgen = { ...leadgen, "f_info[]": text }

	try{
		dataLayer.push(dataLayerInfo)
	} catch(e) {
		console.log('error pushing data layer: '+ e)
	}
	leadgen["celType"] = "getForm"
	leadgen["s[cel]"] = "getForm"
	axios.put(sendLeadUrl + window.location.search, leadgen).then((resp) => {
		console.log(resp.data)
		if (resp.status === 201) {
			if (domainSettings.domain.not_send_cell_for_not_valid_phone && domainSettings.domain.validate_phone && sendToCc) {
				console.log('not sending cell')
			} else {
				SendCell(celtype, leadgen.phone)
			}
		}
	})

	try {
		if (domainSettings.domain.leadPdfFile && domainSettings.domain.leadPdfFile != "") {
			downloadFile("/file-store/" + domainSettings.domain.leadPdfFile)
		}
	} catch(e) {
		console.log("error downloadind file")
	}

}

const googleClientID = (cookie) => {
    if (cookie) {
        let paths = cookie.split('.')
        if (paths.length == 4) {
            paths = paths.slice(2)
        } else {
            paths = [""]
        }
        return paths.join(".")
    }
    return ""
}

const sendLeadToDataLayer = (phone, sdacha, rooms, error) => {
	const SITE_TEMPLATE = 'WA шаблон'

	if (rooms.length > 0) {
		rooms = rooms.join('/')
	} else {
		rooms = 'not set'
	}

	if (sdacha.length > 0) {
		sdacha = sdacha.join('/')
	} else {
		sdacha = 'not set'
	}

	let data = {
		'event': error?'submit_form_error':'submit_form',
		'category': 'conversions',
		'action': 'send_form',
		'label': 'checkout_form',
		'district': 'not set',
		'room': rooms,
		'dateofdelivery': sdacha,
		'price': 'not set',
		'currency': 'RUB',
		'sitetype': SITE_TEMPLATE,
		'city': domainSettings.city.Name,
	}
	return data
}

const phoneClickDataLayer = (phone) => {
	try {
		dataLayer.push({
			'event': 'click_phone_number',
			'category': 'actions',
			'action': 'click',
			'label': phone
		});
	} catch (e) {
		console.log('error sending event to data layer: '+e)
	}
	
}

const startQuizDataLayer = () => {
	try {
		dataLayer.push({
			'event': 'click_button_start',
			'category': 'actions',
			'action': 'click_button',
			'label': 'start_quiz'	
		})
	} catch (e) {
		console.log('error sending event to data layer: '+e)
	}
}

const stepChangeDataLayer = (forward, step) => {
	try {
		dataLayer.push({
			'event': forward?'click_button_step_'+step:'click_button_back_step_'+step,
			'category': 'actions',
			'action': 'click_button',
			'label': forward?'step_'+step:'back_step_'+step,
		});
	} catch (e) {
		console.log('error sending event to data layer: '+e)
	}
}

const clickLinkDataLayer = (jkName) => {
	try {
		dataLayer.push({
			'event': 'click_links',
			'category': 'links',
			'action': 'click_links',
			'label': jkName
		});
	} catch (e) {
		console.log('error sending event to data layer: '+e)
	}
}

const sendNameDataLayer = (error) => {
	try {
		dataLayer.push({
			'event': error?'click_button_get_compilation_error':'click_button_get_compilation',
			'category': 'actions',
			'action': 'click_button',
			'label': 'get_compilation'		
		});
	} catch (e) {
		console.log('error sending event to data layer: '+e)
	}
}

const getSelectedPrice = (form) => {
	let title = null
	domainSettings.domain.Steps.forEach((step) => {
		if (step.type.indexOf('slider') !== -1) {
			title = step.title
		}
	})
	if (form[title]) {
		return form[title]
	}
	return "not set"
}

const generateParamsForUrl = (form) => {
	//?utm_content=1ekran&roistatVisitId=8504168&utm_name=test&utm_phone=+7 ( 999 ) 999 - 99 - 99
	let query = "?utm_content=1ekran"
	if (getParam('utm_campaign') !== null) {
		query += "&utm_campaign=" + getParam('utm_campaign')
	}
	if (getParam('utm_medium') !== null) {
		query += "&utm_medium=" + getParam('utm_medium')
	}
	if (getParam('utm_term') !== null) {
		query += "&utm_term=" + getParam('utm_term')
	}
	if (getParam('utm_source') !== null) {
		query += "&utm_source=" + getParam('utm_source')
	}
	if (getParam('send') !== null) {
		query += "&utm_send=" + getParam('send')
	}
	if (getParam('utm_send') !== null) {
		query += "&utm_send=" + getParam('utm_send')
	}
	query += "&roistatVisitId=" + jsCookie.get('roistat_visit') + "&utm_phone=" + form['phone']
	return query
}

const SendCell = (celType, phone) => {
	console.log("celType " + celType);
	try {
		if (celType === "form" || celType === "getForm") {
			VK.Goal("lead");
			console.log("VK set lead");
		}
	} catch (err) {
		console.log("err VK set lead");
	}

	try {
		if (celType === "form" || celType === "getForm") {
			console.log("fb set Lead {content_name : " + celType + "}");
			let event_id = phone;
			event_id = event_id.split(' ').join('');
			event_id = event_id.replace(/[^0-9]/gim, '');
			console.log("fb set Lead {event_id : " + event_id + "}");
			fbq('track', "Lead", { content_name: "form", 'event_id': md5Hex(event_id) });
		}
	} catch (err) {
		console.log("err fb set Lead");
	}

	try {
		console.log("GA set generate_lead");
		gtag('event', 'generate_lead', {});
	} catch (err) {
		console.log("err GA set generate_lead");
	}

	console.log("YA set " + celType);
	try {
		ym(parseInt(domainSettings.domain.yandex), 'reachGoal', celType);
	} catch (err) {
		console.log('error send to yandex')
	}

	try {
		ym(domainSettings.domain.yandex, 'reachGoal', 'vse');
	} catch (err) {

	}

	try {
		let cookieVal = Cookies.get('testing')
		if (cookieVal && celType == "getForm") { //&& Cookies.get('unique')
			ym(parseInt(domainSettings.domain.yandex), 'reachGoal', cookieVal == window.location.host? "a_site": "b_site");
		}
	} catch (err) {
		console.log('teting error send to yandex')
	}

	try {
		if (celType === "form" || celType === "getForm") {
			console.log(`top mail sended ${domainSettings.domain.mail}`);
			_tmr.push({ id: `${domainSettings.domain.mail}`, type: 'reachGoal', goal: 'all_cells' });
		}
	} catch (err) {
		console.log(`err top mail sended ${domainSettings.domain.mail}`);
	}
}

const GetJKList = async (form, raionsName, roomsName, sdachaName, raionsPrice) => {
	if (raionsName === "" || roomsName === "" || sdachaName === "" || raionsPrice === "") {
		return false
	}
	let data = {
		city_id: domainSettings.city.portal_id,
		sdacha: getSdachaList(form[sdachaName]),
		maxPrice: form[raionsPrice],
		rooms: getRooms(form[roomsName]),
		raions: getRayonCodes(form[raionsName])
	}
	let resp = await axios.post("/jks/", data)
	return resp.data
}

const SendFilter = async (sroks, rooms, number) => {
	let text = ""
	sroks.forEach((srok) => {
		if (text !== "") {
			text += ", "
		}
		text += "Сдача - " + srok
	})
	if (text !== "" && rooms.length > 0) {
		text += "\n"
	}
	let roomsText = "Количество комнат -"
	rooms.forEach((room) => {
		if (roomsText !== "Количество комнат -") {
			roomsText += ", "
		}
		if (room === "0") {
			roomsText += "Студии"
		} else {
			roomsText += room + "-к"
		}
	})
	text += roomsText
	let sID =  await getCityByPortalID(domainSettings.domain.url)
	let data = {
		"s_id": sID,
		"remont": "false",
		"is_loc": "0",
		"l_type": "",
		"text": text,
		"_fbp": jsCookie.get("_fbp"),
		"_fbc": jsCookie.get("_fbc"),
		"fb_id": domainSettings.domain.facebook,
		"user_a": window.navigator.userAgent,
		"metrika_id": domainSettings.domain.yandex,
		"ut_type": domainSettings.domain.url,
		"celType": "form",
		"clientID": document.getElementById('clientID')?document.getElementById('clientID').value:"",
		"utm_content": "1ekran",
		"user_ip": domainSettings.ip,
		"roistatVisitId": jsCookie.get('roistat_visit'),
		"rooms_new[]": [...rooms],
		"srok[]": [...sroks],
		"yclid": getParam('yclid') === null ? "" : getParam('yclid'),
		"gclid": getParam('gclid') === null ? "" : getParam('gclid'),
		"phone": number,
	}

	if (getParam('utm_campaign') !== null) {
		data["utm_campaign"] = getParam('utm_campaign')
	}
	if (getParam('utm_medium') !== null) {
		data["utm_medium"] = getParam('utm_medium')
	}
	if (getParam('utm_term') !== null) {
		data["utm_term"] = getParam('utm_term')
	}
	if (getParam('utm_source') !== null) {
		data["utm_source"] = getParam('utm_source')
	}
	if (getParam('send') !== null) {
		data["utm_send"] = getParam('send')
	}

	if (getParam('utm_send') !== null) {
		data["utm_send"] = getParam('utm_send')
	}

	if (getParam('utm_type') !== null) {
		data["utm_type"] = getParam('utm_type')
	}

	if (getParam('utm_roistat') !== null) {
		data["utm_roistat"] = getParam('utm_roistat')
	}

	let sendData = ""
	Object.keys(data).map((key) => {
		if (sendData != "") {
			sendData += "&"
		}
		sendData += key + "=" + data[key]
	})

	//надо отправлять не как json, а form data
	try {
		/*axios({
			method: "post",
			url: "https://novostroi-v-spb.ru/local/ajax/filters.php",
			data: sendData,
			headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
		}).then((resp) => {
			console.log(resp.data)
		})*/
	} catch (e){}
}

const getCityByPortalID = async (domain) => {
	let url = "/domain-city/"+domain
	if (!domainSettings.portalID) {
		const res = await axios({
			method: "get",
			url: url,
			headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
		});
		domainSettings.portalID = res.data.city 
	}
	return domainSettings.portalID
}

const SetUniqID = async (price, ident) => {
	let now = new Date();
	let sID =  await getCityByPortalID(domainSettings.domain.url)
	if (!ident || ident === "undefined") {
		return
	}
	let data = {
		"send": getParam('utm_send') === null?"" : getParam('yclid'),
		"uniqId": ident,
		"_fbp": jsCookie.get("_fbp"),
		"_fbc": jsCookie.get("_fbc"),
		"fb_id": domainSettings.domain.facebook,
		"s_id": sID,
		"user_a": window.navigator.userAgent,
		"user_ip": domainSettings.ip,
		"celType": "form",
		"roistatVisitId": jsCookie.get('roistat_visit'),
		"domen": domainSettings.domain.url,
		"clientID": domainSettings.domain.clientID,
		"metrika_id": domainSettings.domain.yandex,
		"yclid": getParam('yclid') === null ? "" : getParam('yclid'),
		"gclid": getParam('gclid') === null ? "" : getParam('gclid'),
		"price_ot": price,
		"price_do": null,
		"price": price,
	}

	if (getParam('utm_campaign') !== null) {
		data["utm_campaign"] = getParam('utm_campaign')
	}
	if (getParam('utm_medium') !== null) {
		data["utm_medium"] = getParam('utm_medium')
	}
	if (getParam('utm_term') !== null) {
		data["utm_term"] = getParam('utm_term')
	}
	if (getParam('utm_source') !== null) {
		data["utm_source"] = getParam('utm_source')
	}
	if (getParam('send') !== null) {
		data["utm_send"] = getParam('send')
	}

	if (getParam('utm_send') !== null) {
		data["utm_send"] = getParam('utm_send')
	}

	if (getParam('utm_type') !== null) {
		data["utm_type"] = getParam('utm_type')
	}

	if (getParam('utm_roistat') !== null) {
		data["utm_roistat"] = getParam('utm_roistat')
	}

	let sendData = ""
	Object.keys(data).map((key) => {
		if (sendData != "") {
			sendData += "&"
		}
		sendData += key + "=" + data[key]
	})

	axios({
		method: "post",
		url: "https://mess.g-n.ru/ajax/uniqId.php",//"//chats.g-n.ru/local/api/uniqIdBd/",
		data: sendData,
		headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
	}).then((resp) => {
		console.log(resp.data)
	})
}

export {
	SendData,
	GetJKList,
	generateParamsForUrl,
	SendFilter,
	SetUniqID,
	phoneClickDataLayer,
	startQuizDataLayer,
	stepChangeDataLayer,
	clickLinkDataLayer,
	sendNameDataLayer,
	sendLeadToDataLayer,
}