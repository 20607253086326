import { useEffect, useState } from "react"
import { phoneClickDataLayer, SendFilter, SetUniqID } from "../../utils/send-data"
import MainScreen from "../main-screen"
import PhoneScreen from "../phone-screen"

const Router = () => {
    const [step, setStep] = useState(0)
    const [params, setParams] = useState({})
    const [rooms, setRooms] = useState([])
    const [sroks, setSroks] = useState([])
    const [phone, setPhone] = useState("")
    const [number, setNumber] = useState()
    const [price, setPrice] = useState()
    const [shPolicy, setShPolicy] = useState(true)
    var now = new Date();

    const [ident, setIdent] = useState('9' + now.getMonth() + '' + now.getDate() + '' + now.getHours() + '' + now.getMinutes() + '' + now.getSeconds() + '' + now.getMilliseconds() + '6')
    const nextStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step == null) {
            setStep(0)
        } else {
            setStep(step + 1)
        }
    }

    useEffect(() => {
        SendFilter(sroks, rooms, number)
       
        domainSettings.domain.title = domainSettings.title
        setParams(domainSettings.domain)

        if (domainSettings.domain.yandex !== "") {
            try {
                setTimeout(()=>{
                    ym(parseInt(domainSettings.domain.yandex), 'getClientID', function (clientID) {
                        var newInput = document.createElement("input");
                        newInput.setAttribute('type', 'hidden')
                        newInput.setAttribute('id', 'clientID')
                        newInput.value = clientID;
                        console.log(clientID);    
                        document.body.append(newInput)
                        domainSettings.domain.clientID = clientID;
                        setTimeout(()=>{
                            SetUniqID()
                        },2000)
                        
                    })
                    
                },3000)
            } catch (e) {
                console.log(e)    
            }
        }

        if (domainSettings.title.indexOf("|") != -1) {
            setPrice(parseFloat(domainSettings.title.split("|")[1]))
            return
        }
        /*let prices = domainSettings.prices.prices[0]
        setPrice(Math.round(prices["min"] * 10) / 10)*/
    }, [])


    return <div className="container_main " style={{ background: params.background != "" ? `url("/file-store/${params.background}") center / cover no-repeat` : `` }}>
        {step === 0 ? <MainScreen params={params} price={price} setPrice={setPrice} ident={ident} rooms={rooms} setRooms={setRooms} sroks={sroks} setSroks={setSroks} nextStep={nextStep} number={number} /> : <PhoneScreen params={params} ident={ident} phone={phone} sroks={sroks} rooms={rooms} setPhone={setPhone} number={number} />}
        {domainSettings.organization.name.length > 0 ? <footer>
            {(domainSettings.domain.politics && shPolicy)?<div className="politic">
                <p>Мы используем файлы cookie, чтобы убедиться, что наш веб-сайт работает должным образом. Вы можете продолжить пользоваться нашим сайтом и принять нашу Политику cookie. Изменить ваши настройки cookie можно будет в любой момент.</p>
                <div className="politic_btn" onClick={(e) => {e.preventDefault(); setShPolicy(false)}}>Я ПРИНИМАЮ</div>
            </div>:""}
            <div className="wmain">    
                <div className="fl">{domainSettings.organization.name}, <br />{domainSettings.organization.adress}</div>
                <div className="fr"><a href={`tel:${domainSettings.organization.phone.replace('-', '')}`} onClick={(e) => phoneClickDataLayer(domainSettings.organization.phone)}>{domainSettings.organization.phone}</a></div>
            </div>
        </footer> : ""}
    </div>
}

export default Router